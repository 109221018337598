import { FormatedImage } from '@pretto/website/src/components/FormatedImage/FormatedImage'
import { HighlightText } from '@pretto/website/src/components/HighlightText/HighlightText'
import { slugify } from '@pretto/website/src/templates/blocs/lib/slugify'

import * as S from './RowTextPicture.styles'

export type RowTextPictureProps = {
  buttonLabel?: string
  buttonType: 'cta' | 'pretto_search'
  buttonUrl?: string
  content: string
  highlightDescription?: string
  highlightValue?: string
  highlightUnit?: string
  image: string
  isColored?: boolean
  isFirstRowWithPromises?: boolean
  isHighlightEnabled?: boolean
  isReversed?: boolean
  suptitle?: string
  title: string
}

export const RowTextPicture: React.FC<RowTextPictureProps> = ({
  buttonLabel,
  buttonType,
  buttonUrl,
  content,
  highlightDescription,
  highlightValue,
  image,
  isColored = false,
  isFirstRowWithPromises = false,
  isReversed = false,
  suptitle,
  title,
  ...props
}) => (
  <S.NewBlocRow
    isColored={isColored}
    isFirstRowWithPromises={isFirstRowWithPromises}
    isReversed={isReversed}
    {...props}
  >
    <S.ContentWrapper>
      {suptitle && <S.Suptitle>{suptitle}</S.Suptitle>}

      <S.Title id={slugify(title)}>{title}</S.Title>

      {highlightDescription && (
        <HighlightText
          highlightDescription={highlightDescription}
          highlightValue={highlightValue}
          isColored={isColored}
        />
      )}

      <S.Text allowedTypes={['a', 'strong']}>{content}</S.Text>

      {buttonType === 'cta' && <S.Button href={buttonUrl}>{buttonLabel}</S.Button>}
      {buttonType === 'pretto_search' && <S.PrettoSearch />}
    </S.ContentWrapper>

    <S.ImageWrapper>
      <FormatedImage src={image} format="rectangle" isRounded />
    </S.ImageWrapper>
  </S.NewBlocRow>
)
