import { breakpoints, g } from '@pretto/bricks/components/layout'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import { useResize } from '@pretto/zen/reveal/lib/useResize'

import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const ALLOWED_RATIO = 0.4

type HighlightProps = {
  $isFlexDirectionColumn: boolean
  $isColored: boolean
}

type HighlightNumberProps = {
  $isColored: boolean
}

export type HighlightTextProps = {
  highlightDescription: string
  highlightValue?: string
  isColored?: boolean
}

export const HighlightText: React.FC<HighlightTextProps> = ({
  highlightDescription,
  highlightValue,
  isColored = false,
  ...props
}) => {
  const highlightRef = useRef<HTMLDivElement>(null)
  const highlightValueRef = useRef<HTMLDivElement>(null)

  const [isFlexDirectionColumn, setIsFlexDirectionColumn] = useState(false)

  const size = useResize()

  useEffect(() => {
    if (highlightRef.current && highlightValueRef.current) {
      setIsFlexDirectionColumn(highlightValueRef.current.offsetWidth > ALLOWED_RATIO * highlightRef.current.offsetWidth)
    }
  }, [highlightValue, size])

  return (
    <>
      <HiddenHighlightNumber ref={highlightValueRef}>{highlightValue}</HiddenHighlightNumber>
      <Highlight ref={highlightRef} $isColored={isColored} $isFlexDirectionColumn={isFlexDirectionColumn} {...props}>
        {highlightValue && <HighlightNumber $isColored={isColored}>{highlightValue}</HighlightNumber>}

        <Text allowedTypes={['strong']}>{highlightDescription}</Text>
      </Highlight>
    </>
  )
}

const Highlight = styled.div<HighlightProps>`
  display: flex;
  gap: ${g(1)};
  border-radius: ${g(1)};
  margin-bottom: ${g(3)};
  padding: ${g(3)};
  background-color: ${({ $isColored, theme }) => ($isColored ? theme.colors.neutral5 : theme.colors.accent4)};
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex-direction: ${({ $isFlexDirectionColumn }) => ($isFlexDirectionColumn ? 'column' : 'row')};
    gap: ${({ $isFlexDirectionColumn }) => ($isFlexDirectionColumn ? g(1) : g(3))};
  }
`

const HiddenHighlightNumber = styled.div`
  ${({ theme }) => theme.typos.body2};
  padding-left: ${g(3)};
  padding-right: ${g(3)};
  visibility: hidden;
  max-height: 0;
  width: fit-content;
`

const HighlightNumber = styled.div<HighlightNumberProps>`
  ${({ theme }) => theme.typos.body2};
  color: ${({ $isColored, theme }) => ($isColored ? theme.colors.primary2 : theme.colors.accent1)};
  overflow: hidden;
  flex-shrink: 0;
`

const Text = styled(RichContent)`
  ${({ theme }) => theme.typos.body4};
`
