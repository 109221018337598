import {
  ButtonStoreApple as BaseButtonStoreApple,
  ButtonStoreGoogle as BaseButtonStoreGoogle,
} from '@pretto/bricks/components/buttons/ButtonStore'
import { breakpoints, g } from '@pretto/bricks/components/layout'

import { memo } from 'react'
import styled from 'styled-components'

const ButtonStoreApple = styled(BaseButtonStoreApple)`
  display: block;
`

const ButtonStoreGoogle = styled(ButtonStoreApple).attrs({ as: BaseButtonStoreGoogle })``

const Buttons = styled.div`
  display: grid;
  grid-gap: ${g(2)};
  grid-template: auto auto / auto;
  justify-content: flex-start;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-gap: ${g(3)};
    grid-template: auto / auto auto;
  }
`

const PrettoSearch = props => (
  <Buttons {...props}>
    <ButtonStoreApple />
    <ButtonStoreGoogle />
  </Buttons>
)

export default memo(PrettoSearch)
